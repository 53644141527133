<template>
  <div>
    <b-row
      class="mb-50"
      no-gutters
      align-v="center"
    >
      <b-col>
        <h3 class="mb-0">
          Orders
        </h3>
      </b-col>
      <b-col class="text-right">
        <b-button
          variant="primary"
          size="sm"
          @click="showOrders = false"
        >
          <feather-icon
            icon="PlusIcon"
            size="18"
          />
        </b-button>
      </b-col>
    </b-row>
    <b-table
      v-if="showOrders"
      :items="items"
      responsive
      hover
    />
    <b-form v-if="!showOrders">
      <b-row no-gutters>
        <!-- <b-col cols="4">
          <b-form-group label="address" label-for="address">
            <b-form-select id="address" v-model="selectedAddress">
              <b-form-select-option :value="null"> -- </b-form-select-option>
              <b-form-select-option value="a"> Address A </b-form-select-option>
              <b-form-select-option value="b"> Address B </b-form-select-option>
              <b-form-select-option value="c"> Address C </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="4" class="pl-50 pr-50">
          <b-form-group label="accept payment via" label-for="payment">
            <b-form-select id="payment" v-model="selectedPayment">
              <b-form-select-option :value="null"> -- </b-form-select-option>
              <b-form-select-option value="a"> Payment A </b-form-select-option>
              <b-form-select-option value="b"> Payment B </b-form-select-option>
              <b-form-select-option value="c"> Payment C </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="preferred delivery date"
            label-for="delivery-date"
          >
            <b-form-input
              id="delivery-date"
              v-model="deliveryDate"
              placeholder="delivery date"
              required
            />
          </b-form-group>
        </b-col> -->
        <b-col cols="12">
          <hr class="invoice-spacing m-0">
          <b-form @submit.prevent>
            <b-card
              no-body
              class="invoice-preview-card mb-0"
            >
              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section pl-0 pr-0">
                <div
                  ref="form"
                  class="repeater-form"
                >
                  <b-row
                    v-for="(item, index) in invoiceData.items"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            Item
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            Cost
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            Qty
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            Price
                          </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <label class="d-inline d-lg-none">Item</label>
                            <v-select
                              v-model="item.itemTitle"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="itemsOptions"
                              label="itemTitle"
                              :clearable="false"
                              class="mb-2 item-selector-title"
                              placeholder="Select Item"
                              @input="(val) => updateItemForm(index, val)"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <label class="d-inline d-lg-none">Cost</label>
                            <b-form-input
                              v-model="item.cost"
                              type="number"
                              class="mb-2"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none">Qty</label>
                            <b-form-input
                              v-model="item.qty"
                              type="number"
                              class="mb-2"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none">Price</label>
                            <p class="mb-1">
                              ${{ item.cost * item.qty }}
                            </p>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <label
                              class="d-inline d-lg-none"
                            >Description</label>
                            <b-form-textarea
                              v-model="item.description"
                              class="mb-2 mb-lg-0"
                            />
                          </b-col>
                          <b-col>
                            <p class="mb-0">
                              Discount: 0% 0% 0%
                            </p>
                          </b-col>
                        </b-row>
                        <div
                          class="
                            d-flex
                            flex-column
                            justify-content-between
                            border-left
                            py-50
                            px-25
                          "
                        >
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                          <feather-icon
                            :id="`form-item-settings-icon-${index}`"
                            size="16"
                            icon="SettingsIcon"
                            class="cursor-pointer"
                          />

                          <!-- Setting Item Form -->
                          <b-popover
                            :ref="`form-item-settings-popover-${index}`"
                            :target="`form-item-settings-icon-${index}`"
                            triggers="click"
                            placement="lefttop"
                          >
                            <b-form @submit.prevent>
                              <b-row>
                                <!-- Field: Discount -->
                                <b-col cols="12">
                                  <b-form-group
                                    label="Discount(%)"
                                    :label-for="`setting-item-${index}-discount`"
                                  >
                                    <b-form-input
                                      :id="`setting-item-${index}-discount`"
                                      :value="null"
                                      type="number"
                                    />
                                  </b-form-group>
                                </b-col>

                                <!-- Field: Tax 1 -->
                                <b-col cols="6">
                                  <b-form-group
                                    label="Tax 1"
                                    :label-for="`setting-item-${index}-tax-1`"
                                  >
                                    <v-select
                                      :dir="
                                        $store.state.appConfig.isRTL
                                          ? 'rtl'
                                          : 'ltr'
                                      "
                                      :value="'10%'"
                                      :options="[
                                        '0%',
                                        '1%',
                                        '10%',
                                        '14%',
                                        '18%',
                                      ]"
                                      :input-id="`setting-item-${index}-tax-1`"
                                      :clearable="false"
                                    />
                                  </b-form-group>
                                </b-col>

                                <!-- Field: Tax 2 -->
                                <b-col cols="6">
                                  <b-form-group
                                    label="Tax 2"
                                    :label-for="`setting-item-${index}-tax-2`"
                                  >
                                    <v-select
                                      :dir="
                                        $store.state.appConfig.isRTL
                                          ? 'rtl'
                                          : 'ltr'
                                      "
                                      :value="'10%'"
                                      :options="[
                                        '0%',
                                        '1%',
                                        '10%',
                                        '14%',
                                        '18%',
                                      ]"
                                      :input-id="`setting-item-${index}-tax-2`"
                                      :clearable="false"
                                    />
                                  </b-form-group>
                                </b-col>

                                <b-col
                                  cols="12"
                                  class="d-flex justify-content-between mt-1"
                                >
                                  <b-button
                                    variant="outline-primary"
                                    @click="
                                      () => {
                                        $refs[
                                          `form-item-settings-popover-${index}`
                                        ][0].$emit('close');
                                      }
                                    "
                                  >
                                    Apply
                                  </b-button>
                                  <b-button
                                    variant="outline-secondary"
                                    @click="
                                      () => {
                                        $refs[
                                          `form-item-settings-popover-${index}`
                                        ][0].$emit('close');
                                      }
                                    "
                                  >
                                    Cancel
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-form>
                          </b-popover>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemForm"
                >
                  Add Item
                </b-button>
              </b-card-body>
              <hr class="invoice-spacing mt-0">

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0 pl-0 pr-0">
                <b-row>
                  <!-- Col: Sales Persion -->
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                  >
                    <label
                      for="invoice-data-sales-person"
                      class="text-nowrap mr-50"
                    >Sales Person:</label>
                    <b-form-input
                      id="invoice-data-sales-person"
                      v-model="invoiceData.salesPerson"
                      placeholder="Edward Crowley"
                    />
                  </b-col>

                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Subtotal:
                        </p>
                        <p class="invoice-total-amount">
                          $1800
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Discount:
                        </p>
                        <p class="invoice-total-amount">
                          $28
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Tax:
                        </p>
                        <p class="invoice-total-amount">
                          21%
                        </p>
                      </div>
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Total:
                        </p>
                        <p class="invoice-total-amount">
                          $1690
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Note -->
              <!--<b-card-body class="invoice-padding p-0">
                <span class="font-weight-bold">Note: </span>
                <b-form-textarea v-model="invoiceData.note" />
              </b-card-body>-->
            </b-card>
          </b-form>
        </b-col>
      </b-row>
      <div class="text-right">
        <b-button
          variant="primary"
          class="mr-1"
          @click="showOrders = true"
        >Cancel</b-button>
        <b-button
          variant="primary"
          @click="showOrders = true"
        >
          Save
        </b-button>
      </div>
    </b-form>
    <hr class="dropdown-divider">
  </div>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api";
// import { heightTransition } from "@core/mixins/ui/transition";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import {
  BTable,
  BRow,
  BCol,
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  // BFormSelect,
  // BFormSelectOption,
  BFormTextarea,
  BCard,
  BCardBody,
  BPopover,
  // BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import invoiceStoreModule from "./invoiceStoreModule";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    // BFormSelect,
    // BFormSelectOption,
    vSelect,
    BFormTextarea,
    BCard,
    BCardBody,
    BPopover,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [
        {
          id: 1,
          quantity: 5,
          total: 10,
        },
        {
          id: 2,
          quantity: 2,
          total: 10,
        },
        {
          id: 3,
          quantity: 4,
          total: 8,
        },
      ],
      showOrders: true,
      selectedAddress: null,
      selectedPayment: null,
      deliveryDate: "",
      // lineAddressSeconde: "",
      // zipCode: "",
      // comment: "",
    };
  },
  // mixins: [heightTransition],
  mounted() {
    // this.initTrHeight();
  },
  created() {
    // window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    // window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.invoiceData.items.push(
        // eslint-disable-next-line comma-dangle
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );

      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.row[0].offsetHeight);
      //   setTimeout(() => {
      //     this.$refs.form.style.overflow = null;
      //   }, 350);
      // });
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1);
      // this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    // initTrHeight() {
    //   this.trSetHeight(null);
    //   this.$nextTick(() => {
    //     this.trSetHeight(this.$refs.form.scrollHeight);
    //   });
    // },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = "app-invoice";

    // Register module
    // eslint-disable-next-line curly
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      // eslint-disable-next-line nonblock-statement-body-position
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      // eslint-disable-next-line curly
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        // eslint-disable-next-line nonblock-statement-body-position
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    const clients = ref([]);
    // eslint-disable-next-line arrow-parens
    store.dispatch("app-invoice/fetchClients").then((response) => {
      clients.value = response.data;
    });

    const itemFormBlankItem = {
      item: null,
      cost: 0,
      qty: 0,
      description: "",
    };

    const invoiceData = ref({
      id: 5037,
      client: null,

      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],

      salesPerson: "",
      note: "It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!",
      paymentMethod: null,
    });

    const itemsOptions = [
      {
        itemTitle: "App Design",
        cost: 24,
        qty: 1,
        description: "Designed UI kit & app pages.",
      },
      {
        itemTitle: "App Customization",
        cost: 26,
        qty: 1,
        description: "Customization & Bug Fixes.",
      },
      {
        itemTitle: "ABC Template",
        cost: 28,
        qty: 1,
        description: "Bootstrap 4 admin template.",
      },
      {
        itemTitle: "App Development",
        cost: 32,
        qty: 1,
        description: "Native App Development.",
      },
    ];

    const updateItemForm = (index, val) => {
      const { cost, qty, description } = val;
      invoiceData.value.items[index].cost = cost;
      invoiceData.value.items[index].qty = qty;
      invoiceData.value.items[index].description = description;
    };

    const paymentMethods = ["Bank Account", "PayPal", "UPI Transfer"];

    return {
      invoiceData,
      clients,
      itemsOptions,
      updateItemForm,
      itemFormBlankItem,
      paymentMethods,
    };
  },
};
</script>

<style scoped>
.invoice-total-item {
  display: flex;
  justify-content: space-between;
}
</style>
